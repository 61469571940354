
import { computed, defineComponent, ref } from "vue";
import { stocksExpirations, useProducts } from "@/graphql/product/products";
import { activeActivity } from "@/plugins/i18n";
import { stockExpNotifier } from "@/data/global";
import { CONSTANTS, formatNumber, getSign } from "@/graphql/utils/utils";
import { useI18n } from "vue-i18n";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { printRapport } from "@/components/rapport/printer";

const FILTER = {
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  expiredAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  note: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  quantity: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  gap: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  inclTax: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  threshold: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export default defineComponent({
  name: "StocksExpirations",
  setup: function () {
    //:lazy="true"
    // :paginator="true"
    //:rows="recentSales.meta.itemsPerPage"
    //:total-records="recentSales.meta.totalItems"
    // @page="refetcher($event.page + 1, 'page')"
    const { products, loading } = useProducts();
    const { t, d } = useI18n();
    const stocks = computed(() => {
      const values = stocksExpirations(products.value);
      stockExpNotifier.expired = values[0].length;
      stockExpNotifier.threshold = values[1].length;
      const options = ["stockInExpiration", "stockInThreshold"].map(
        (v, value) => ({
          label: t(`stock.${v}`) + "(" + values[value].length + ")",
          value,
        })
      );
      return {
        values,
        options,
      };
    });

    const filters = ref(FILTER);
    function clearFilter() {
      Object.assign(filters.value, FILTER);
    }
    const selectedOptions = ref(0);
    function print() {
      if (!stocks.value.values[selectedOptions.value].length) return;
      printRapport(`<table>
      <thead>
        <tr>
          <th class="p-text-uppercase" colspan="8">
            <h2 class="p-pt-3">${t("stock.expiration")}</h2>
          </th>
        </tr>
        <tr>
          <th class="p-text-left" style="border-right: unset" colspan="1">
            ${t("date")}
          </th>
          <th class="p-text-center">
            ${t("product.name")}
          </th>
          <th class="p-text-center">
            ${t("stock.quantity")}
          </th>
          <th class="p-text-center">
            ${t("stock.threshold")}
          </th>
          <th class="p-text-center">
            ${t("stock.gap")}
          </th>
          <th class="p-text-center">
            ${t("stock.sellingPrice")}
          </th>
          <th class="p-text-center">
            ${t("payment.note")}
          </th>
          <th class="p-text-right">
            ${t("stock.expiredAt")}
          </th>
        </tr>
      </thead>
      <tbody>
        ${stocks.value.values[selectedOptions.value]
          .map(
            (data) => `<tr>
          <td class="p-text-left">
           ${d(data.createdAt)}
          </td>
          <td>${data.name}</td>
          <td>${formatNumber(data.quantity)}</td>
          <td>
            ${data.threshold ? formatNumber(data.threshold) : data.threshold}
          </td>
          <td>${formatNumber(data.gap)}</td>
          <td>${formatNumber(data.inclTax)}</td>
          <td>${data.note}</td>
          <td class="p-text-right">
            ${data.expiredAt ? d(data.expiredAt) : ""}
          </td>
        </tr>`
          )
          .join("")}
      </tbody>
    </table>`);
    }
    return {
      stocks,
      loading,
      currency: activeActivity.value.currencySymbol,
      rowsPerPageOptions: CONSTANTS.rowsPerPageOptions,
      selectedOptions,
      clearFilter,
      filters,
      print,
    };
  },
});
